import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Paragraph,
	TextContainer,
	Ul,
	Icon,
	IconSettings,
	IconEdit,
	IconChevron,
	IconCopy,
	IconShare,
	InlineText,
	SrOnly,
} from "../../..";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	DocsTable,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Button } from "./button";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Button",
	notepad: "https://hackmd.io/NccEDrrZQCaePKv7LQquWA?edit",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Button"
			subTitle="Communicate which actions are available and enable users to trigger them."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Button} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Buttons are used in different contexts within the platform. To support this variety of
					use, the button component features properties to adjust its appearance and functionality.
					The most important properties are showcased here. They are split up into these categories:
				</Paragraph>
				<Ul
					items={[
						<>
							<a href="#variants">Variants</a>. Use these to choose an overall visual style that
							matches the button’s purpose. In most cases, you'll need one of the{" "}
							<a href="#regular">regular</a> variants. The <a href="#cta">call-to-action (CTA)</a>{" "}
							variants are intended for marketing purposes within the platform.
						</>,
						<>
							<a href="#visual">Visual</a>. Use these to adjust certain visual characteristics.
							Specifically, the <a href="#size">size</a> of a button, the presence of an{" "}
							<a href="#icon">icon</a>, the usage of <a href="#html-content">HTML content</a>, and
							presenting related buttons as a visually connected{" "}
							<a href="#button-group">button group</a>.
						</>,
						<>
							<a href="#state">State</a>. Use these to enable a button’s{" "}
							<a href="#disabled">disabled</a> or <a href="#loading">loading</a> state.
						</>,
						<>
							<a href="#link">Link</a>. Use these to make a button{" "}
							<a href="#behave-like-a-link">behave like a link</a> and (optionally){" "}
							<a href="#open-in-new-tab-or-window">open in a new tab or window</a>.
						</>,
					]}
				/>
				<Header.H3>Variants</Header.H3>
				<Header.H4>Regular</Header.H4>
				<Paragraph>
					Use the <Code>variant</Code> property when the default button implementation doesn't
					support your use case. Consult the table below to find out which button variant suits your
					use case:
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Variant</th>
							<th>Purpose</th>
							<th>Usage guideline</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Primary</td>
							<td>Most important or frequently used action on a page.</td>
							<td>
								Only use once per page (excluding overlays, such as modals or sidepanels). Not
								required on every page.
							</td>
						</tr>
						<tr>
							<td>Secondary</td>
							<td>Alternative actions to a page's primary action.</td>
							<td>Always place them directly next to a primary button.</td>
						</tr>
						<tr>
							<td>Default</td>
							<td>Actions of regular importance.</td>
							<td>
								Use when the action is neither primary or secondary in nature. Use when multiple
								destructive actions are needed on a page.
							</td>
						</tr>
						<tr>
							<td>Destructive</td>
							<td>Actions that delete or remove data.</td>
							<td>
								Only use once per page (excluding overlays, such as modals or sidepanels). Use
								default buttons if multiple destructive actions are needed on a page.
							</td>
						</tr>
						<tr>
							<td>Borderless</td>
							<td>Less important or infrequently used actions.</td>
							<td>Always use an icon, either with or without a label.</td>
						</tr>
					</tbody>
				</DocsTable>
				<Example fn={variantExample} />
				<Header.H4>CTA</Header.H4>
				<Paragraph>
					You can also use the <Code>variant</Code> property to style the button component as
					call-to-action (CTA) buttons. Only use CTA buttons for marketing purposes, such as
					requesting a free trial.
				</Paragraph>
				<Paragraph>
					There are three CTA button variants: primary, secondary and default. When you only need a
					single CTA button, choose the variant that is most visually appealing within its context
					of use. When using multiple adjacent CTA buttons, keep the following guidelines in mind:
				</Paragraph>
				<Ul
					items={[
						"Use a different variant for each button.",
						"Use the primary variant for the most important or most frequently used action.",
						"Use the secondary variant for an action that serves as an alternative to the primary action.",
						"Always place the secondary variant directly next to the primary variant.",
						"Use the default variant for all other actions.",
					]}
				/>
				<Example fn={ctaExample} />
				<Header.H3>Visual</Header.H3>
				<Header.H4>Size</Header.H4>
				<Paragraph>
					Use the <Code>size</Code> property to adjust a button's size. The default size is{" "}
					<Code>medium</Code> and should be used in most cases. Use <Code>small</Code> buttons when
					space is limited, such as in the Page Report. Use <Code>large</Code> buttons for page
					level actions, such as exporting a page to HTML. Buttons placed next to each other should
					all have the same size.
				</Paragraph>
				<Example fn={sizeExample} />
				<Header.H4>Icon</Header.H4>
				<Paragraph>
					To add an icon to a button, place an{" "}
					<GatsbyLink to="/lib/components/Visuals/Icon">Icon</GatsbyLink> component inside a button
					component, as shown in the example below. Visit the{" "}
					<GatsbyLink to="/lib/components/Visuals/Icons Overview">Icons Overview</GatsbyLink> page
					for an overview of all available Fancy icons.
				</Paragraph>
				<Paragraph>
					Place the icon before the label, to make it appear to the left of the label. Place the
					icon after the label, to make it appear to the right of the label. Only place an icon to
					the right of the label to signal directionality. This could, for instance, be a “next”
					button with a chevron pointing right.
				</Paragraph>
				<Paragraph>
					Borderless buttons should always have an icon, whether it’s accompanied by a label or not.
					Other button variants should only include an icon to emphasize a button’s purpose. Don’t
					add an icon because of aesthetic reasons. When in doubt, don’t add an icon. People with
					visual impairments may not see the icon at all, so make sure the button’s (aria-)label
					makes the button’s purpose clear.
				</Paragraph>
				<Paragraph>
					Use buttons that only contain an icon sparingly. Use them when space is limited or the
					button is repeated many times on a page. Also, the icon should be universally known, such
					as a pen for editing, a magnifying glass for searching, or a cogwheel for settings.
				</Paragraph>
				<Example fn={iconExample} />
				<Header.H4>HTML content</Header.H4>
				<Paragraph>
					When adding a string or a number as a child to the Button component it will be wrapped in
					the <GatsbyLink to="/lib/components/Text/Inline text">InlineText</GatsbyLink> component to
					get the proper font styling. When adding an{" "}
					<GatsbyLink to="/lib/components/Visuals/Icons">Icon</GatsbyLink> component or other HTML
					content as a child to the Button component it will not be wrapped in the{" "}
					<GatsbyLink to="/lib/components/Text/Inline text">InlineText</GatsbyLink> component. To
					get the proper font styling when using HTML code around your string or number, you should
					wrap it in the <GatsbyLink to="/lib/components/Text/Inline text">InlineText</GatsbyLink>{" "}
					component yourself.
				</Paragraph>
				<Example fn={HTMLContentExample} />
				<Header.H4>Button group</Header.H4>
				<Paragraph>
					When you have multiple buttons with similar functionality and equal importance, you can
					present them as a button group. To do this, place the buttons inside a{" "}
					<Code>{"<Button.Group>"}</Code> component, as shown in the example below. Keep the
					following guidelines in mind, when using button groups:.
				</Paragraph>
				<Ul
					items={[
						<>
							Only put <Code>default</Code> <a href="#regular">variant</a> buttons inside a button
							group.
						</>,
						<>
							Use the same <a href="#size">size</a> for every button in the button group.
						</>,
						<>
							Either provide all or none of the buttons with an <a href="#icon">icon</a>.
						</>,
						<>
							Don't use button groups in tables. They provide a poor keyboard navigation experience.
						</>,
					]}
				/>
				<Example fn={buttongroupExample} />
				<Header.H3>State</Header.H3>
				<Header.H4>Loading</Header.H4>
				<Paragraph>
					Buttons that trigger network operations should always enter a loading state when pressed.
					This is done by setting the button’s <Code>loading</Code> property to <Code>true</Code>.
					This state communicates to users that the button’s action is being processed. Buttons that
					don't trigger network operations should never enter a loading state, since their actions
					will be carried out immediately.
				</Paragraph>
				<Example fn={loadingExample} />
				<Header.H4>Disabled</Header.H4>
				<Paragraph>
					To disable a button, set its <Code>disabled</Code> property to <Code>true</Code>. This
					state communicates to users that the button’s action is unavailable.
				</Paragraph>
				<Paragraph>
					Use the disabled state sparingly. When you use it, make sure the rest of the page makes it
					clear why the action is unavailable and what is required to make it available. Try not to
					use this state for submit buttons in forms. It’s better to let users press the submit
					button, so the subsequent validation errors can explain, what exactly users need to do to
					proceed.
				</Paragraph>
				<Example fn={disabledExample} />
				<Header.H3>Link</Header.H3>
				<Header.H4>Behave like a link</Header.H4>
				<Paragraph>
					When a link answers the question “What can I do?”, rather than “Where can I go?”, it
					should look like a button. For example, you might have a link labelled "Add users”, which
					navigates users to the first page of an add-user flow. To users, that label makes it feel
					like they're triggering an action. To visually support this impression, the link should
					look like a button.
				</Paragraph>
				<Paragraph>
					The button component’s <Code>href</Code> property supports this use case. When set to a
					URL, the component's HTML tag will change from <Code>{"<button>"}</Code> to{" "}
					<Code>{"<a>"}</Code>. However, it will still look like a button.
				</Paragraph>
				<Example fn={hrefExample} />
				<Header.H4>Open in new tab or window</Header.H4>
				<Paragraph>
					When a button links to a page outside the Siteimprove Platform, it's generally best to
					open it in a new tab or window (depending on the user's browser setting). This can be
					achieved by setting the <Code>openNew</Code> property to <Code>true</Code>. Only use this
					property in combination with the <Code>href</Code> property.
				</Paragraph>
				<Example fn={openNewExample} />
				<Header.H2>Properties</Header.H2>
				<Header.H3>Button</Header.H3>
				<Knobs
					component={Button}
					initialProps={{ children: "Button text", onClick: () => console.log("clicked") }}
				/>
				<Header.H3>Button.Group</Header.H3>
				<Knobs
					component={Button.Group}
					initialProps={{
						"aria-label": "Descriptive title for the button group",
						children: (
							<>
								<Button>
									<Icon>
										<IconEdit />
									</Icon>
									Edit
								</Button>
								<Button>
									<Icon>
										<IconCopy />
									</Icon>
									Copy
								</Button>
								<Button>
									<Icon>
										<IconShare />
									</Icon>
									Share
								</Button>
							</>
						),
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>Variant</Header.H4>
					<Ul
						items={[
							<>
								Use the <a href="#regular">variant</a> property to choose an overall visual style
								that matches the button’s purpose.
							</>,
							<>
								Only use the <a href="#cta">call-to-action (CTA)</a> variants for marketing purposes
								within the platform.
							</>,
						]}
					/>
					<Header.H4>Size</Header.H4>
					<Ul
						items={[
							<>
								Use the <a href="#size">size</a> property to adjust a button's size.
							</>,
							<>
								The default size is <Code>medium</Code> and should be used in most cases.
							</>,
							<>
								Use <Code>small</Code> when space is limited, such as in the Page Report.
							</>,
							<>
								Use <Code>large</Code> buttons for page level actions, such as exporting a page to
								HTML.
							</>,
							"Buttons placed next to each other should all have the same size.",
						]}
					/>
					<Header.H4>Icon</Header.H4>
					<Ul
						items={[
							<>
								To add an icon to a button, place an
								<GatsbyLink to="/lab/components/Visuals/Icons">icon component</GatsbyLink> inside a
								button component.
							</>,
							<>
								Visit the
								<GatsbyLink to="/lab/components/Visuals/Icons Overview">
									icons overview
								</GatsbyLink>{" "}
								page for an overview of all available Fancy icons.
							</>,
							"Add an icon to a button to emphasize it's purpose. Except when using a borderless button, then always use an icon.",
							"Don’t add an icon because of aesthetic reasons.",
							"When in doubt, don’t add an icon.",
							"Place icons to the left of the button labels. Except when they signal directionality, then place them to the right. For example: a “Next” button with a chevron pointing right.",
							"People with visual impairments may not see the icon at all, so make sure the button’s (aria-)label makes the button’s purpose clear.",
							"Use buttons that contain an icon, but no label, sparingly. Only use them when space is limited or the button is repeated many times on a page.",
							"When a button only contains an icon, the icon should be universally known, such as a pen for editing, a magnifying glass for searching, or a cogwheel for settings.",
						]}
					/>
					<Header.H4>Button group</Header.H4>
					<Ul
						items={[
							<>
								When you have adjacent buttons with similar functionality and equal importance,
								consider using a <a href="#button-group">button group</a>. Simply wrap the buttons
								in a <Code>{"<Button.Group>"}</Code> component
							</>,
							<>
								Only put default <a href="#regular">variant</a> buttons inside a button group.
							</>,
							<>
								Use the same <a href="#size">size</a> for every button in the button group.
							</>,
							<>
								Either provide all or none of the buttons with an <a href="#icon">icon</a>.
							</>,
							<>
								Don't use button groups in tables. They provide a poor keyboard navigation
								experience.
							</>,
						]}
					/>
					<Header.H4>State</Header.H4>
					<Ul
						items={[
							<>
								Buttons that trigger network operations should always enter a{" "}
								<a href="#loading">loading state</a> when pressed.
							</>,
							<>
								Buttons that don't trigger network operations should never enter a{" "}
								<a href="#loading">loading state</a>.
							</>,
							<>
								Use the button’s <a href="#disabled">disabled state</a> sparingly. When you use it,
								make sure the rest of the page makes it clear why the action is unavailable and what
								is required to make it available.
							</>,
							<>
								Try not to use the <a href="#disabled">disabled state</a> for form submit buttons.
								It’s better to let users press the submit button, so the subsequent validation
								errors can explain, what exactly users need to do to proceed.
							</>,
						]}
					/>
					<Header.H4>Link</Header.H4>
					<Ul
						items={[
							<>
								The <Code>href</Code> property turns the button component into a link, that still
								looks like a button. Use this property when you need a UI element that{" "}
								<a href="#behave-like-a-link">behaves like a link</a>, but "feels" like a button. In
								other words, when its label answers the question “What can I do?”, rather than
								“Where can I go?”.
							</>,
							<>
								When a button links to a page outside the Siteimprove Platform, it's generally best
								to <a href="#open-in-new-tab-or-window">open it in a new tab or window</a>. This can
								be achieved by setting its <Code>openNew</Code> property to <Code>true</Code>.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						The button’s label answers the question “Where can I go?”, rather than “What can I do?”.
						In that case, use the <GatsbyLink to="/lib/components/Navigation/Link">link</GatsbyLink>{" "}
						component instead.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Ul
						items={[
							"Use unique, descriptive labels - avoid generic labels like learn more.",
							"If a generic label can't be avoided, make sure to provide a more descriptive aria-label.",
							"Always check a button's focus behavior. Sometimes, correct focus behavior is built in. For instance, when you close a modal, focus will move back to the button that opened it. However, correct focus behavior like this is not guaranteed. For example, when you press a button inside a table row to delete that row, focus will not automatically move back to the previous element.",
							"If the button toggles another piece of content's visibility, add an `aria-expanded`. If the button specifically toggles a popover, modal, or dialog, also add an `aria-haspopup`.",
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"In general, use verb + object for button labels: Create policy, Add widget, Recheck page.",
							"It’s okay to use a single word when the button completes a workflow (Done), cancels an action (Cancel), or closes an overlay (Close).",
							"Avoid double-negatives to confirm or cancel an action: “Are you sure you want to cancel?” [OK] [Cancel].",
							"Leave out articles like “the”, “a”, “an”. The more concise you can be, the better.",
							"Avoid exclamation marks (!), ellipses (…), brackets () and other punctuation and symbols (&+*>|).",
							"Use sentence case: Create dashboard, not Create Dashboard.",
						]}
					/>
				</InlineMessage>
				<Header.H2>Notable changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Ul
					items={[
						<>
							There used to be a separate property for turning a default button into a CTA button.
							Now, the <a href="#variants">variant</a> property also includes CTA button variants.
						</>,
						<>
							The <Code>IconOnlyButton</Code> component has been deprecated. Now, you create a
							button only containing an <a href="#icon">icon</a> by placing an{" "}
							<GatsbyLink to="/lib/components/Visuals/Icons">icon component</GatsbyLink> inside a{" "}
							<Code>Button</Code> compoment.
						</>,
						<>
							The <Code>LinkButton</Code> component has been deprecated. This component looked like
							a button, but behaved like a link. Now, you achieve the same result by using the{" "}
							<Code>Button</Code> component and providing its <Code>href</Code> property with a URL.
						</>,
					]}
				/>
			</TextContainer>
		</ContentSection>
	</>
);

const variantExample = () => (
	<>
		<Button onClick={() => console.log("clicked")} variant="primary">
			Primary
		</Button>
		<Button onClick={() => console.log("clicked")} variant="secondary">
			Secondary
		</Button>
		<Button onClick={() => console.log("clicked")}>Default</Button>
		<Button onClick={() => console.log("clicked")} variant="destructive">
			Destructive
		</Button>
		<Button onClick={() => console.log("clicked")} variant="borderless">
			<Icon>
				<IconEdit />
			</Icon>
			Borderless
		</Button>
	</>
);

const ctaExample = () => (
	<>
		<Button onClick={() => console.log("clicked")} variant="ctaPrimary">
			Primary
		</Button>
		<Button onClick={() => console.log("clicked")} variant="ctaSecondary">
			Secondary
		</Button>
		<Button onClick={() => console.log("clicked")} variant="ctaDefault">
			Default
		</Button>
	</>
);

const sizeExample = () => (
	<>
		<Button onClick={() => console.log("clicked")} size="small">
			Small
		</Button>
		<Button onClick={() => console.log("clicked")}>Medium</Button>
		<Button onClick={() => console.log("clicked")} size="large">
			Large
		</Button>
	</>
);

const iconExample = () => (
	<>
		<Button onClick={() => console.log("clicked")}>
			<Icon>
				<IconEdit />
			</Icon>
			Edit item
		</Button>
		<Button onClick={() => console.log("clicked")}>
			Next
			<Icon rotation="270">
				<IconChevron />
			</Icon>
		</Button>
		<Button onClick={() => console.log("clicked")} aria-label="icon only button">
			<Icon>
				<IconSettings />
			</Icon>
		</Button>
		<Button onClick={() => console.log("clicked")} aria-label="icon only button with fragment">
			<>
				<Icon>
					<IconShare />
				</Icon>
			</>
		</Button>
	</>
);

const HTMLContentExample = () => (
	<>
		<Button onClick={() => console.log("clicked")}>
			<InlineText>
				<span aria-hidden="true">HTML content</span>
				<SrOnly>Reveal HTML content</SrOnly>
			</InlineText>
		</Button>
	</>
);

/** @ignore [visual] */
const loadingExample = () => (
	<>
		<Button onClick={() => console.log("clicked")} loading>
			Save
		</Button>
		<Button onClick={() => console.log("clicked")} variant="ctaDefault" loading>
			Save
		</Button>
		<Button onClick={() => console.log("clicked")} aria-label="Save" loading>
			<Icon>
				<IconSettings />
			</Icon>
		</Button>
	</>
);

const disabledExample = () => (
	<>
		<Button onClick={() => console.log("clicked")} disabled>
			Disabled
		</Button>
		<Button onClick={() => console.log("clicked")} variant="ctaDefault" disabled>
			Disabled
		</Button>
		<Button onClick={() => console.log("clicked")} aria-label="icon only button" disabled>
			<Icon>
				<IconEdit />
			</Icon>
		</Button>
	</>
);

const buttongroupExample = () => (
	<>
		<Button.Group aria-label="Descriptive title for the button group">
			<Button>
				<Icon>
					<IconEdit />
				</Icon>
				Edit
			</Button>
			<Button>
				<Icon>
					<IconCopy />
				</Icon>
				Copy
			</Button>
			<Button>
				<Icon>
					<IconShare />
				</Icon>
				Share
			</Button>
		</Button.Group>
	</>
);

const hrefExample = () => (
	<Button href="https://my2.siteimprove.com/Settings/Users/Create" variant="primary">
		Add users
	</Button>
);

const openNewExample = () => (
	<Button
		href="https://siteimprove.com/en/book-a-consultation/"
		openNew={true}
		variant="ctaPrimary"
	>
		Book a time to talk to us
	</Button>
);
